import React from "react";
import { useParams } from "react-router-dom";

import { useContents } from "fond/hooks/useContents";

import { BaseProjectList } from "./BaseProjectList";

interface RouteParams {
  folderId: string;
}

/**
 * Folders content component that renders contents within a folder
 */
const FoldersProjectList: React.FC = () => {
  const { items } = useContents();
  const { folderId } = useParams<keyof RouteParams>();

  const folderItems = items.filter((item) => {
    let itemFolderId;
    if (item.EntityType === "project") {
      itemFolderId = item.FolderID ?? null;
    } else if (item.EntityType === "folder") {
      itemFolderId = item.ParentID ?? null;
    } else if (item.EntityType === "report") {
      itemFolderId = item.Folder?.ID ?? null;
    }
    return itemFolderId === (folderId || null);
  });

  return <BaseProjectList items={[...folderItems]} />;
};

export default FoldersProjectList;
