import { createSelector } from "@reduxjs/toolkit";

import { accountSlice } from "fond/api/accountSlice";
import { apiSlice } from "fond/api/apiSlice";
import { Workflow, WorkflowState } from "fond/types";

type GetWorkflowsResponse = {
  Workflows: Workflow[];
};

export const workflowSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getWorkflows: build.query<Workflow[], void>({
      query: () => "/v2/workflows",
      transformResponse: (response: GetWorkflowsResponse): Workflow[] => response.Workflows,
    }),
  }),
});

export const { useGetWorkflowsQuery } = workflowSlice;

const selectWorkflowsResult = workflowSlice.endpoints.getWorkflows.select();
const selectWorkflowsData = createSelector(selectWorkflowsResult, (workflowsResult) => workflowsResult.data);

export const selectProjectWorkflowStatuses = createSelector(selectWorkflowsData, (workflows) =>
  Object.fromEntries(workflows?.map((workflow) => [workflow.Version.Project.ID, workflow.Status]) ?? [])
);
